import React from 'react';

export default ()=>
    <footer className="flex flex-wrap justify-center bg-black text-white">
        <div className="">
            <h3>Crisnaldo Carvalho Santos </h3>
        </div>
        <div>
            <h3> © 2020</h3>
        </div>
    </footer>